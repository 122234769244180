// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   kicker -> text
//   headline -> text / Überschrift
//   subheadline -> text
//   stat_items -> bloks / Kennzahlen Items
// end of automatic

import React, { useRef, useEffect } from "react"
import BlockWrapper from "../technical/BlockWrapper"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

const useCountUp = () => {
  const refCountUpTrigger = useRef(null)
  const refCountUp = useRef([])
  refCountUp.current = []

  const refCountUpCollection = (el) => {
    if (el && !refCountUp.current.includes(el)) {
      refCountUp.current.push(el)
    }
  }

  useEffect(() => {
    gsap.from(refCountUp.current, {
      duration: 2,
      stagger: 0.25,
      ease: "ease.out",
      innerText: 0,
      snap: { innerText: 1 },
      scrollTrigger: {
        trigger: refCountUpTrigger.current,
        toggleActions: "play none none reverse",
        start: "start 65%",
        end: "bottom 25%",
      },
    })
  }, [])
  return [refCountUpTrigger, refCountUpCollection]
}

const StatsBlock = ({ block }) => {
  const [refCountUpTrigger, refCountUpCollection] = useCountUp()
  return (
    <BlockWrapper
      block={block}
      centerHeadline="true"
      className="text-white bg-primary"
      blockPadding="py-20"
    >
      <div
        ref={refCountUpTrigger}
        className={`flex flex-row justify-center flex-wrap -mx-grid ${
          block.headline?.length > 0 ? "mt-5" : ""
        }`}
      >
        {block.stat_items?.length > 0 &&
          block.stat_items.map((item, index) => (
            <div className="w-full mt-10 md:w-1/2 lg:w-1/4 px-grid" key={index}>
              <div className="text-center">
                <span
                  ref={refCountUpCollection}
                  className="inline-block mb-5 text-6xl font-medium"
                >
                  {item.number}
                </span>
                <span className="block w-[4.375rem] h-1 bg-white mx-auto"></span>
                {item.text && (
                  <Richtext className="mt-5 prose-xl" text={item.text} />
                )}
              </div>
            </div>
          ))}
      </div>
    </BlockWrapper>
  )
}

export default StatsBlock
